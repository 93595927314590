$primary-color: #2a3d66; // Deep blue for primary actions
$secondary-color: #f0f2fa; // Light lavender for backgrounds
$accent-color: #f2545b; // Vibrant coral for highlights
$text-color: #1c1c1e; // Dark text color
$font-family: "Poppins", sans-serif;

.counter-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-items: center;
  padding: 30px;
  background-color: $secondary-color;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 40px auto;
  font-family: $font-family;

  .coin-display,
  .price-display,
  .final-display {
    font-size: 1.4rem;
    margin: 0;
    color: $text-color;
    font-weight: 600;
  }

  .coin-display {
    color: $primary-color;
  }

  .price-display {
    color: $accent-color;
  }

  .final-display {
    color: darken($primary-color, 15%);
  }

  .counter-button {
    padding: 12px 18px;
    margin: 10px;
    border: none;
    background-color: $primary-color;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

    &:hover {
      background-color: lighten($primary-color, 10%);
      transform: translateY(-3px);
    }

    &:active {
      transform: translateY(0);
    }
  }

  .payment-button {
    padding: 14px 20px;
    background-color: $accent-color;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

    &:hover {
      background-color: darken($accent-color, 10%);
      transform: translateY(-3px);
    }

    &:active {
      transform: translateY(0);
    }
  }
}

@media screen and (max-width: 768px) {
  .counter-container {
    padding: 20px;
    max-width: 90%;

    .coin-display,
    .price-display,
    .final-display {
      font-size: 1.2rem;
    }

    .counter-button, .payment-button {
      padding: 10px 16px;
      font-size: 0.9rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .counter-container {
    padding: 15px;
    max-width: 100%;

    .coin-display,
    .price-display,
    .final-display {
      font-size: 1rem;
    }

    .counter-button, .payment-button {
      padding: 8px 14px;
      font-size: 0.85rem;
    }
  }
}
