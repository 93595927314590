.cont{
    width: 98.8vw;
    border: 2px solid gold;
}
.nav{
    width: 100%;
    background-color: #344239;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

}

.nav div{
    padding-left: 10px;
}

.nav a{
    text-decoration: none;
    color: white;
}

.prodCont{
    display: flex;
    /* justify-content: space-evenly; */
    overflow: scroll;
    padding: 5px;
}

@media (min-width: 768px) {
    .prodCont {
      justify-content: space-evenly; /* Distribute content evenly */
    }
  }
.prod{
    width: 11rem;
    /* line-height: 1rem; */
    text-decoration: none;
    color: black;
    margin-left: 2px;
    margin-right: 2px;
    border: 0.1px solid gold;
    border-radius: 5px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.prod p{
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
    margin: 1px;
}


.prod img{
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    margin-bottom: 5px;
}