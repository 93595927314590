.login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;

  .card {
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    max-width: 500px;
    padding: 40px 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    



    .right {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h1 {
        color: #333;
        font-size: 28px;
        text-align: center;
        margin-bottom: 10px;      }
      
      form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        
        input {
          border: 1px solid #ccc;
          border-radius: 6px;
          padding: 12px 15px;
          font-size: 16px;
          transition: border-color 0.2s ease;

          &:focus {
            border-color: #007bff;
            outline: none;
          }
        }

        .text-danger {
          color: #dc3545;
          font-size: 14px;
          text-align: center;
        }

        
        button {
          padding: 12px;
          border-radius: 6px;
          border: none;
          background-color: #007bff;
          color: #ffffff;
          font-weight: bold;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.2s ease;
          width: 100%;
        }

        .btn-sm {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: transparent;
          border: none;
          font-size: 14px;
          cursor: pointer;
          padding: 0;
          margin-top: -10px;

          &:hover {
            text-decoration: underline;
          }

          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
