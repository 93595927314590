img{
    /* width: 150px,
    height= 150px
    position: "relative",
    objectFit: "cover", */
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.imgdhjfd{
    width: 100px;
    height: 100px;
object-fit: scale-down;}


.all-sub, .one-sub{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

label{
    text-transform: uppercase;
}
