.posts{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 1px;
  }

  .pulsse{
    border: 0.1px solid gold;
    animation: pulse 0.5s infinite;
    transition: transform 0.3s ease-in-out;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 2px 4px gold;
    }
    50% {
      box-shadow: 0 5px 8px gold;
    }
    100% {
      box-shadow: 0 2px 4px gold;
    }
  }