/* land.css */
.landing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .jumbotron {
    background-color: rgba(99, 133, 168, 0.8);
    color: white;
    padding: 3rem;
    margin-top: 2rem;
    border-radius: 10px;
  }
  
  .footer {
    background-color: #343a40;
    color: white;
    padding: 10px 0;
    margin-top: 2rem;
    width: 100%;
  }
  
  .footer a {
    color: #28a745;
  }
  
  @media (max-width: 768px) {
    .jumbotron {
      padding: 2rem;
    }
  
    .container {
      padding: 2rem 1rem;
    }
  }
  